*,
*::after,
*::before {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
}
body {
  display: flex;

  font-size: 0.875rem;
  font-family: graphik, "graphik-medium" !important;
  -webkit-font-smoothing: antialiased;
  background-color: rgba(255, 255, 255, 1);
  line-height: 1.5;
  margin: 0;
  padding: 0;
}
#root {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

#error-wrapper .notification-item {
  box-shadow: none !important;
}

/* tbody > tr > td {
  padding: 0 7px !important;
} */
