.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.react-datepicker__input-container {
  display: flex;
  flex-direction: row;
  width: auto;
  flex-grow: 1;
}
.customDatePickerWidth > div > div.react-datepicker__input-container input {
  width: 100%;
}
/* .react-datepicker__input-container input */

/* 
.customDatePickerWidth > div > div.react-datepicker__input-container

*/
