@font-face {
  font-family: graphik;
  src: url(../assets/fonts/Graphik-Regular-Web.eot);
  src: url(../assets/fonts/Graphik-Regular-Web.eot) format("embedded-opentype"),
    url(../assets/fonts/Graphik-Regular-Web.woff) format("woff");
}

@font-face {
  font-family: "graphik-light";
  src: url(../assets/fonts/Graphik-Light-Web.eot);
  src: url(../assets/fonts/Graphik-Light-Web.eot) format("embedded-opentype"),
    url(../assets/fonts/Graphik-Light-Web.woff) format("woff");
}

@font-face {
  font-family: "graphik-medium";
  src: url(../assets/fonts/Graphik-Medium-Web.eot);
  src: url(../assets/fonts/Graphik-Medium-Web.eot) format("embedded-opentype"),
    url(../assets/fonts/Graphik-Medium-Web.woff) format("woff");
}

@font-face {
  font-family: "graphik-semibold";
  src: url(../assets/fonts/Graphik-Semibold-Web.eot);
  src: url(../assets/fonts/Graphik-Semibold-Web.eot) format("embedded-opentype"),
    url(../assets/fonts/Graphik-Semibold-Web.woff) format("woff");
}

@font-face {
  font-family: "graphik-bold";
  src: url(../assets/fonts/Graphik-Bold-Web.eot);
  src: url(../assets/fonts/Graphik-Bold-Web.eot) format("embedded-opentype"),
    url(../assets/fonts/Graphik-Bold-Web.woff) format("woff");
}

@font-face {
  font-family: "graphik-black";
  src: url(../assets/fonts/Graphik-Black-Web.eot);
  src: url(../assets/fonts/Graphik-Black-Web.eot) format("embedded-opentype"),
    url(../assets/fonts/Graphik-Black-Web.woff) format("woff");
}

@font-face {
  font-family: "roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/roboto.eot");
  src: local("Roboto"), local("Roboto-Regular"),
    url("../assets/fonts/roboto.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/roboto.woff2") format("woff2"),
    url("../assets/fonts/roboto.woff") format("woff"),
    url("../assets/fonts/roboto.ttf") format("truetype"),
    url("../assets/fonts/roboto.svg#Roboto") format("svg");
}
